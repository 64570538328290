<template>
  <div class='kyc-digilocker-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='kyc-digilocker-title'>Aadhar KYC (DigiLocker)</div>
            <div class='kyc-digilocker-stepper'>Hello {{ clientName }}!</div>

            <div class='kyc-digilocker-sub-title-description'>
              share your address-related information from
              <u style='color: #5c4e7a ; cursor: pointer;'>DigiLocker account</u> for KYC.
            </div>
            <div class='kyc-digilocker-sub-title-description'>
              Your Aadhar number/copy will not be stored. The Aadhar and the PAN
              <span style='font-weight: 700'>XXXXXXXXXX</span> should belong to you, the applicant.
              If it does not ,
              <span style='color: #5c4e7a'>start over.</span>
            </div>

            <div class='continue-to-digi-btn-wrapper'>
              <a-button type='primary' @click='onClickContinue'>Continue to DigiLocker</a-button>
            </div>
            <div class='kyc-guidelines-description'>
              **By clicking the above button, I agree I have read the
              <span style='color: #5c4e7a'> Aadhaar user consent document</span>
              and voluntarily consent to share my Aadhaar information (excluding the Aadhaar number)
              with Castlegate for conducting online KYC for opening a trading and Demat account
              adhering to KRA regulation (2011) and PMLA guidelines (2002).
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
// import { DeleteOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    // DeleteOutlined,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    const router = useRouter();
    const clientName = computed(
      () => store.getters['onboardingStore/getClientName'],
    ).value;
    const onClickContinue = () => {
      router.push('/onboarding/upload/address');
    };
    return {
      onClickContinue,
      value1: ref(),
      checked: ref(false),
      clientName,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/kycDigilocker.scss';
</style>
